import React, { useCallback } from "react";
import "./App.css";
import useLogout from "./hooks/useLogOut";
import Page from "./Page";
import UserInfo from "./UserInfo";
import { useDropzone } from "react-dropzone";
import * as Scry from "scryfall-api";
import {
  GuessResults,
  knownSiteStrategy,
  stripCardDataFromFilename,
  attemptOcr,
} from "./strategies";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

type ScryFallCardResult = Scry.Card | undefined;

const _badSessionDict: any = {};

export const _getSessionVariable = (key: any) => {
  return _badSessionDict[key] || false;
};

export const _setSessionVariable = (key: any, val: any) => {
  return (_badSessionDict[key] = val);
};

let firstGuesses: GuessResults;

function htmlToElements(html: string): NodeListOf<ChildNode> {
  var template = document.createElement("template");
  template.innerHTML = html;
  return template.content.childNodes;
}

function stripCardDataFromInsecureStuff(
  uriList: string,
  imgNode: HTMLImageElement
): GuessResults {
  const retVal: GuessResults = {
    sort_order: [],
    data: {},
    resolvers: {},
  };

  knownSiteStrategy(uriList, imgNode, retVal);

  return retVal;
}

function Card(props: any) {
  return (
    <img
      src={props.card.image_uris.normal}
      alt={props.card.name}
      style={{ width: 230 }}
    ></img>
  );
}

function Stack(props: any) {
  return (
    <div className="stack">
      <h1>{props.title}</h1>
      <Droppable droppableId={props.title}>
        {(provided: any) => {
          return (
            <>
              {props.cards.map(function (card: Scry.Card, i: string) {
                return <Card card={card} key={i} />;
              })}
              {provided.placeholder}
            </>
          );
        }}
      </Droppable>
    </div>
  );
}

function Deck(props: any) {
  return (
    <div className="deck">
      {/* {props.deck.map(function (card: Scry.Card, i: string) {
        return <Card card={card} key={i} />;
      })} */}

      <Stack title="unassigned" cards={props.deck}></Stack>
    </div>
  );
}

const myDeck: Scry.Card[] = [];

function App() {
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  function handleCurrentCard(card: Scry.Card | null, ocrOptions: any) {
    if (card == null) {
      console.log("Couldnt find a card!");
      attemptOcr(ocrOptions);
    } else {
      myDeck.push(card);
      forceUpdate();
    }
  }

  const doWithDataOnDrop = (acceptedFiles: any) => {
    if (acceptedFiles && acceptedFiles.length === 1 && acceptedFiles[0].name) {
      const guesses = stripCardDataFromFilename(
        acceptedFiles[0].name,
        firstGuesses
      );

      const tryAndTryAgain = () => {
        if (guesses.sort_order.length) {
          const current_guess = guesses.sort_order.shift();

          if (current_guess === undefined) {
            return;
          }

          const promise = guesses.resolvers[current_guess](
            guesses.data[current_guess]
          );

          const onFulfilled = (value: ScryFallCardResult) => {
            if (value === undefined) {
              tryAndTryAgain();
            } else {
              handleCurrentCard(value, undefined);
            }
          };

          const onRejected = (error: any) => {
            console.error(error);
          };

          promise.then(onFulfilled, onRejected);
        } else {
          handleCurrentCard(null, { acceptedFiles, guesses, tryAndTryAgain });
        }
      };

      tryAndTryAgain();
    } else {
      console.error("Some real hot bullshit was dropped on me, yo.");
    }
  };

  const insecureOnDrop = (e: any) => {
    e.preventDefault();

    const uriList: string | null =
      e.dataTransfer.getData("text/uri-list") || null;

    const data: NodeListOf<ChildNode> | null =
      htmlToElements(e.dataTransfer.getData("text/html")) || null;

    const imgNode = data[0] as HTMLImageElement;

    firstGuesses = stripCardDataFromInsecureStuff(uriList || "", imgNode);
  };

  const secureOnDrop = (acceptedFiles: any) => {
    doWithDataOnDrop(acceptedFiles);
  };

  const onDrop = useCallback(secureOnDrop, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const logout = useLogout();

  const onDragEnd = (result: any) => {
    debugger;
    console.log(result);
  };

  return (
    <Page requireAuth>
      <div className="App">
        <div
          {...getRootProps({
            onDrop: (event) => {
              insecureOnDrop(event);
            },

            onClick: (event) => {
              console.log(event);
              event.stopPropagation(); // disble the "click and show a file dialog behavior"
            },
          })}
        >
          <input {...getInputProps()} />
          <DragDropContext onDragEnd={onDragEnd}>
            <header className="App-header">
              <UserInfo />
              <button onClick={logout}>Log out maybe?</button>
              <Deck deck={myDeck} taco="delicious" />
            </header>
          </DragDropContext>
        </div>
      </div>
    </Page>
  );
}

export default App;
