import React from "react";
import { useCookies } from "react-cookie";

const useLogout = () => {
  const [, , removeCookie] = useCookies();

  const logout = React.useCallback(() => {
    fetch("/i/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    }).then(() => {
      removeCookie("sessionToken");
    });
  }, [removeCookie]);

  return logout;
};

export default useLogout;
