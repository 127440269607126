import * as Scry from "scryfall-api";

import MAGIC_DICTIONARY from "../MagicDictionary";

import { _setSessionVariable, _getSessionVariable } from "../App";

type ScryFallGuessFunction = (data: any) => Promise<Scry.Card | undefined>;
export type GuessResults = {
  sort_order: string[];
  data: {
    [key: string]: any;
  };
  resolvers: {
    [key: string]: ScryFallGuessFunction;
  };
};

const checkMagicDictionary = (magicWord: string): boolean => {
  return MAGIC_DICTIONARY.has(magicWord.toLowerCase());
};

function parseQueryVariables(query: string): any {
  const vars = query.split("&");

  const ret: any = {};

  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    ret[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }

  return ret;
}

export const stripCardDataFromFilename = (
  filename: string,
  _firstGuesses: GuessResults
): GuessResults => {
  // trim the file extension off, if present and if there's only one damned dot in the filename.
  const dots = filename.match(new RegExp("\\.", "g")) || [];
  const dotCount: number = dots.length as number;

  const dotIdx = filename.lastIndexOf(".");
  if (dotIdx !== -1 && dotCount === 1) {
    filename = filename.substring(0, dotIdx);
  }

  // star city mode.  They use camel case?!
  if (dotCount === 4 && filename.indexOf("__") !== -1) {
    filename = filename
      .substring(0, filename.indexOf("__"))
      .replace(/([a-z])([A-Z])/g, "$1 $2");
  }

  filename = filename.toLowerCase();

  const tokens = filename.split(/[^A-Za-z]/);

  const retVal: any = _firstGuesses;

  // badStrategy(retVal); // This makes a bad request to Scryfall so we can see the retry logic

  // matches SETCODE CARDNUMBER format
  setAndNumberStrategy(retVal, tokens);

  // just provides a string version of the file to do a search with
  if (!retVal.data.dumbestNameGuess) {
    dumbNameStrategy(retVal, tokens);
  }

  return retVal;
};

const storeTcgplayerComStrategy = (
  uri: string,
  imgNode: HTMLImageElement,
  retVal: any
) => {
  // maybe this should rightfully be called the dawnglare strategy, amusingly...
  try {
    const tokens = uri.split("/");
    if (tokens[3] === "magic") {
      const set = tokens[4]; // sadly not a set code
      console.log(set);
      const subTokens = tokens[5].split("?");
      const name = subTokens[0].split("-");

      // we can figure out what site was linking directly to tcgplayer from this!
      const params = parseQueryVariables(subTokens[1]);
      console.log(params);

      dumbNameStrategy(retVal, name);
    } else {
      debugger;
    }
  } catch (e) {
    debugger;
  }

  //parseQueryVariables();
};

const starCityGamesStrategy = (
  uri: string,
  imgNode: HTMLImageElement,
  retVal: any
) => {
  // maybe this should rightfully be called the dawnglare strategy, amusingly...
  try {
    const tokens: any = (imgNode as any).href.split("/")[3].split("-");

    const setCode = tokens[tokens.length - 3];
    const collectorNumber = parseInt(tokens[tokens.length - 2]);

    setAndNumberStrategy2(retVal, setCode, collectorNumber);
  } catch (e) {
    debugger;
  }

  //parseQueryVariables();
};

const scryfallStrategy = (imgNode: HTMLImageElement, retVal: any) => {
  // Scryfall should specifically have imgNode.title  = "{cardname} ({setcode})".
  // Error otherwise: we should know if it changes.
  try {
    const tmp = imgNode.title;
    const idx = tmp.lastIndexOf(" ");
    const name = tmp.substring(0, idx);
    const set = tmp.substring(idx + 2, idx + 5);

    retVal.data.setGuess = {
      set_code: name,
      card_number: set,
    };
    retVal.sort_order.push("setGuess");
    retVal.resolvers["setGuess"] = setAndNumberResolver;
  } catch (e) {
    debugger;
    // TODO: error report why this failed so we can fix it
    // it'll likely be an index out of bounds and if it was from scryfall this SHOULD be reliable?
  }
};

const channelFireballStrategy = (imgNode: HTMLImageElement, retVal: any) => {
  // maybe this should rightfully be called the dawnglare strategy, amusingly...
  try {
    const tmp = imgNode.alt.split(" ");
    dumbNameStrategy(retVal, tmp);
  } catch (e) {
    debugger;
  }
};

export const knownSiteStrategy = (
  uri: string,
  imgNode: HTMLImageElement,
  retVal: any
) => {
  try {
    const site = uri.split("/")[2].toLowerCase();

    if (site === "c1.scryfall.com") {
      /// we did an insecure drop on ourself and this is a bad escape hack
      // TODO remove this
      console.error("IMPLEMENT GOOD DRAGGING");
      return;
    }

    switch (site) {
      case "scryfall.com":
        scryfallStrategy(imgNode, retVal);
        break;
      case "channelfireball.com":
      case "product-images.channelfireball.com":
        channelFireballStrategy(imgNode, retVal);
        break;
      case "store.tcgplayer.com":
        storeTcgplayerComStrategy(uri, imgNode, retVal);
        break;
      case "starcitygames.com":
        starCityGamesStrategy(uri, imgNode, retVal);
        break;
      case "edhrec.com": // TODO This means we'll need the scryfall filename cache before going public
        break; //nothing to be done here atm; fall back to other strategies.
      default:
        //TODO: add reporting that we need to add a new site strategy!
        debugger;
    }
  } catch (e) {
    //TODO: add reporting that we need to add a new site strategy!
  }

  // retVal.data.dumbestNameGuess = dumbword;
  // retVal.sort_order.push("dumbestNameGuess");
  // retVal.resolvers["dumbestNameGuess"] = dumbestNameResolver;
};

function dumbNameStrategy(retVal: any, tokens: string[]) {
  let dumbword = "";
  tokens.forEach((word) => {
    if (checkMagicDictionary(word)) {
      console.log(`${word} is a magic word.`);
      if (dumbword.length > 0) {
        dumbword += " ";
      }
      dumbword += word;
    } else {
      console.log(`${word} is NOT a magic word.`);
    }
  });

  retVal.data.dumbestNameGuess = dumbword;
  retVal.sort_order.push("dumbestNameGuess");
  retVal.resolvers["dumbestNameGuess"] = dumbestNameResolver;
}

function badStrategy(retVal: any) {
  retVal.data.badRequest = {};
  retVal.sort_order.push("badRequest");
  retVal.resolvers["badRequest"] = badResolver;
}

function setAndNumberStrategy(retVal: any, tokens: string[]) {
  if (
    tokens.length >= 2 &&
    tokens[0].length === 3 &&
    !isNaN(Number(tokens[1]))
  ) {
    retVal.data.setGuess = {
      set_code: tokens[0],
      card_number: tokens[1],
    };
    retVal.sort_order.push("setGuess");
    retVal.resolvers["setGuess"] = setAndNumberResolver;
  }
}

function setAndNumberStrategy2(
  retVal: any,
  setCode: string,
  collectorNumber: number
) {
  retVal.data.setGuess2 = {
    set_code: setCode,
    card_number: collectorNumber,
  };
  retVal.sort_order.push("setGuess2");
  retVal.resolvers["setGuess2"] = setAndNumberResolver;
}

async function badResolver(data: any): Promise<Scry.Card | undefined> {
  return Scry.Cards.bySet("xxx", -1);
}

async function setAndNumberResolver(data: any): Promise<Scry.Card | undefined> {
  return Scry.Cards.bySet(data.set_code, data.card_number);
}

async function dumbestNameResolver(data: any): Promise<Scry.Card | undefined> {
  return Scry.Cards.byName(data, true);
}

async function ocrResolver(data: any): Promise<Scry.Card | undefined> {
  return Scry.Cards.byName(data.titleGuess, true);
}

export const attemptOcr = (ocrOptions: any) => {
  console.log("time to attempt ocr...");

  const { acceptedFiles, guesses, tryAndTryAgain } = ocrOptions;

  acceptedFiles.forEach((file: any) => {
    const reader = new FileReader();

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      const arrayBuffer: ArrayBuffer = reader.result as ArrayBuffer;
      const uint8View = new Uint8Array(arrayBuffer);
      const binary = String.fromCharCode(...uint8View);
      const base64String = btoa(binary);

      fetch("/i/ocr/", {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        body: JSON.stringify({
          image_data: base64String,
          original_size: uint8View.length,
        }),
      })
        .then((r) => {
          return r.json();
        })
        .then((results) => {
          console.log(results);

          const titleGuess = results.titleGuess.trim();

          const lastGuess = _getSessionVariable("my-last-ocr-guess");

          if (titleGuess && titleGuess.length && titleGuess !== lastGuess) {
            _setSessionVariable("my-last-ocr-guess", titleGuess);
            guesses.data.ocrTitleGuess = { titleGuess };
            guesses.sort_order.push("ocrTitleGuess");
            guesses.resolvers["ocrTitleGuess"] = ocrResolver;
            tryAndTryAgain();
          }
        });
    };
    reader.readAsArrayBuffer(file);
  });
};
