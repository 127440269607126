import React from 'react';
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import './App.css';

type Props = {
  onSignIn: () => void;
};

const AuthPage = (props: Props) => {
  const {onSignIn} = props;

  const onLoginFailure = React.useCallback((e: any) => {
    console.log(e);
    alert('login failed, try again');
  }, []);

  const onLoginSuccess = React.useCallback(
    (e: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      if ('tokenId' in e) {
        fetch('/i/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            googleToken: e.tokenId,
          }),
        }).then(() => {
          onSignIn();
        });
      } else {
        console.error(e);
        onLoginFailure(e);
      }
    },
    [onLoginFailure, onSignIn],
  );

  return (
    <GoogleLogin
      clientId="443445390726-7cnilncs7khui12vnccab5hdkvn0a9b9.apps.googleusercontent.com"
      buttonText="Login"
      onSuccess={onLoginSuccess}
      onFailure={onLoginFailure}
      cookiePolicy={'single_host_origin'}
    />
  );
};

export default AuthPage;
