import { User } from "apiModels/user";
import React from "react";

const UserInfo = () => {
  const [user, setUser] = React.useState<User | undefined>();

  React.useEffect(() => {
    fetch("/i/myUser", {
      credentials: "include",
      method: "POST",
    })
      .then((r) => r.json())
      .then((u: { user: User }) => setUser(u.user));
  }, []);

  if (user) {
    return (
      <div>
        <div>
          {user.firstName} {user.lastName}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default UserInfo;
