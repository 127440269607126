import React from "react";
import { useCookies } from "react-cookie";
import "./App.css";
import AuthPage from "./AuthPage";

type Props = {
  requireAuth?: boolean;
  children: React.ReactNode;
};

const Page = (props: Props) => {
  const [cookies] = useCookies(["sessionToken"]);
  const isLoggedIn = cookies.sessionToken;

  const onSignIn = React.useCallback(() => {
    window.location.reload();
  }, []);

  if (isLoggedIn) {
    return <>{props.children}</>;
  } else {
    return <AuthPage onSignIn={onSignIn} />;
  }
};

export default Page;
